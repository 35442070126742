import * as React from "react";
import i18next from '../i18n.js'
import { StaticImage } from "gatsby-plugin-image"


import HomeContact from "../components/homeContact";
import Layout from "../components/layout";

import * as Styles from "../styles/layout.module.scss";

const Kontakt = ({ pageContext }) => {
  
  var translationContext = { locale: "de",
                             defaultMenu: true };
  var { t } = i18next
  return (
    <Layout
      title="Kontakt"
      translationContext={translationContext}
      pageContext={pageContext}
    >
      <div class={Styles.singleTopicLayout}>        
        <main>
          <h1>{t('headerContact')}</h1>
          <HomeContact locale={pageContext.locale} />
          <h2 id="directions">Anreise (Büro)</h2>
          <p>
            Brandeis Consulting GmbH <br/>
            Julius-Hattry Str. 1 <br/>
            68163 Mannheim <br/>
          </p>
          <div>
            <h2>Vom Hauptbahnhof Mannheim (10 Minuten zu Fuß)</h2>
            <p>Den Tunnel unter den Gleisen hinter Gleis 12 in Richtung Lindenhof verlassen. Über den  Lindenhofplatz zur Glücksteinallee, dieser nach links ca. 600 Meter folgen bis zum Mafinex Gebäude. Dieses über den hintersten Eingang vom Schotterparkplatz aus betreten. 
            <h2>Mit dem  Bus</h2>
            ...kann man sich noch 500 Meter sparen. Dazu an der Haltestelle <b>MA Hauptbahnhof Süd</b> den Bus Nr. 65 Richtung Hochschule West nehmen. Bei der nächsten Haltestelle <b>Glücksteinallee</b> muss man gleich wieder aussteigen. Der Bus fährt alle 20 Minuten, siehe <b><a href="https://www.rnv-online.de/media/rnv-online.de/Fahrtinfo/Fahrplaene/Aushangfahrplaene/65_2_15689.pdf">Fahrplan</a> </b></p>
            <div className={Styles.hideOnMobile}>
        <StaticImage src="../images/Fussweg vom Bahnhof.png"
          alt="Anfahrt Mafinex"
          placeholder="blurred"
          layout="constrained"
          width={1200} ></StaticImage>
   </div>
          </div>
        </main>
      </div> 
    </Layout>
  );
};

export default Kontakt;
